* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Gotham Bold";
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold'), url("fonts/Gotham-Font/Gotham-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gotham Medium";
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Medium'), url("fonts/Gotham-Font/Gotham-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gotham Light";
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light'), url("fonts/Gotham-Font/Gotham-Light.woff") format("woff");
}

body,html {
  font-family: "Gotham Medium";
  scroll-behavior: smooth;
}